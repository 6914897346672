import React from 'react';
import "./rotatess.css"; // Make sure to import your CSS file

const Component = () => {
  return (
    <div className='hello'>
      <div className="rotate yeuta"></div>
      <div className="rotate duita"></div>
      <div className="rotate tinta"></div>
      <div className="rotate d"></div>
      {/* <div className="rotate e"></div>
      <div className="rotate f"></div>
      <div className="rotate g"></div>
      <div className="rotate h"></div>
      <div className="rotate i"></div>
      <div className="rotate j"></div>
      <div className="rotate k"></div> */}

    </div>
  );
}

export default Component;
